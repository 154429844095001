export interface CreateTemplate {
  duplicateTemplate: string
  editTemplate: string
}

const createTemplate: CreateTemplate = {
  duplicateTemplate: "DUPLICATE TEMPLATE",
  editTemplate: "EDIT TEMPLATE",
}

export default createTemplate
