import { Template, TemplateCategory } from "@src/queries/template"
import { User } from "@src/queries/user"

// is 4pc super hero
export const is4PCSupportHero = (user: User): boolean => {
  if (user.user.isSupportUser) {
    return true
  }

  return false
}

// can this user access eblast platform
export const canAccessEBlast = (user: User): boolean => {
  if (is4PCSupportHero(user)) {
    return true
  }

  // allow template manager
  if (user.session.canAccessSite) {
    return true
  }

  return false
}

// can this user manage templates
export const canManageTemplates = (user: User): boolean => {
  if (is4PCSupportHero(user)) {
    return true
  }

  // allow template manager
  if (user.session.canManageTemplates) {
    return true
  }

  return false
}

// can this user edit this template
export const canUserEditThisTemplate = (template: Template, user: User): boolean => {
  if (!canManageTemplates(user)) {
    return false
  }

  if (is4PCSupportHero(user)) {
    return true
  }

  if (template.category === TemplateCategory.local) {
    // local
    if (template.coverTemplates && template.coverTemplates.length === 1) {
      // only current cover has access to this template
      return true
    }
  }

  return false
}
