import gql from "graphql-tag"

export enum TemplateCategory {
  local = "local",
  global = "global",
  smartbook = "smartbook"
}

export const templateTypeOptions: { label: string, value: string }[] = [
  {
    label: "Save to Local Template",
    value: TemplateCategory.local
  },
  {
    label: "Save to Smartbook Template",
    value: TemplateCategory.smartbook
  },
  {
    label: "Save to Global Template",
    value: TemplateCategory.global
  },
]

export interface CreateTemplateInput {
  category: TemplateCategory
  name: string
  description: string
  templateContent: string
  hasLockedSections: boolean
  coverKeys: number[]
}

export interface CoverTemplate {
  id: number
  coverKey: number
}

export interface Template extends CreateTemplateInput {
  id: number
  createdAt: Date
  createdBy: string
  modifiedAt: Date
  coverTemplates: CoverTemplate[]
}

export const TEMPLATE_FRAGMENT = gql`
  fragment Template on Template {
    id
    category
    coverKey
    name
    description
    templateContent
    hasLockedSections
    coverTemplates
    createdAt
    modifiedAt
    createdBy
  }
`

export const GET_TEMPLATES = gql`
  query getTemplates {
    templates @rest(type: "Template", path: "/v1/templates?PageSize=1000") {
      ...Template
    }
  }
  ${TEMPLATE_FRAGMENT}
`

export const GET_TEMPLATE = gql`
  query getTemplate($templateId: number) {
    template(templateId: $templateId)
      @rest(type: "Template", path: "/v1/templates/{args.templateId}") {
      ...Template
    }
  }
  ${TEMPLATE_FRAGMENT}
`

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($templateId: number, $body: CreateTemplateInput!) {
    template(templateId: $templateId, body: $body)
      @rest(
        type: "Template"
        path: "/v1/templates"
        method: "POST"
        bodyKey: "body"
      ) {
      ...Template
    }
  }
  ${TEMPLATE_FRAGMENT}
`

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($templateId: number, $body: CreateTemplateInput!) {
    template(templateId: $templateId, body: $body)
      @rest(
        type: "Template"
        path: "/v1/templates/{args.templateId}"
        method: "PUT"
        bodyKey: "body"
      ) {
      ...Template
    }
  }
  ${TEMPLATE_FRAGMENT}
`
