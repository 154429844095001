import { HomePageGetStartedModal } from "./homePageGetStartedModal"

const home: HomePageGetStartedModal = {
  heading: "Glad to see you back!",
  headingDescription: "Design & Send Email Marketing & Campaigns ",
  footerBtn1: "CREATE",
  footerBtn2: "MANAGE",
  description:
    "We’ve noticed that you left things a little unfinished the last time you used EBLAST. Would you like to <b>continue where you left off</b> or <b>start a new campaign</b>?",
  section1Title: "Start New eBlast Emails & Campaigns",
  section1SubTitle: "for my practice",
  section1Description:
    "Start using eBlast to send direct mail campaigns to your patients.",
  section2Title: "Manage eBlast Emails & Campaigns",
  section2SubTitle: "for my practice",
  section2Description:
    "Manage eBlast campaigns you’ve created in the past.",
}

export default home
