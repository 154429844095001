interface FileSelection {
  sortByLocation: string
  sortByProvider: string
  selectionAppearHere: string
  footerDescription: string

  corporateHeading: string
  publicHeading: string
  unsplashHeading: string

  corporateDescription: string
  publicDescription: string
  unsplashDescription: string

  corporateBtn: string
  publicBtn: string
  unsplashBtn: string
}

const fileSelection: FileSelection = {
  sortByLocation: "By Location",
  sortByProvider: "By Provider",
  selectionAppearHere: "Selection will appear here",
  footerDescription: "Choose from various image libraries.",

  corporateHeading: "Custom Corporate Assets",
  publicHeading: "4PC Image Library",
  unsplashHeading: "Stock Image Library",

  corporateDescription: "Choose one of our custom images to add, or to replace an existing image in your email.",
  publicDescription: "Choose one of our public images to add, or to replace an existing image in your email.",
  unsplashDescription: "Choose one of our custom images to add, or to replace an existing image in your email.",

  corporateBtn: "CORPORATE LIBRARY",
  publicBtn: "4PC IMAGE LIBRARY",
  unsplashBtn: "STOCK IMAGES",
}

export default fileSelection
