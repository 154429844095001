interface Campaigns {
  campaigns: string
  campaign: string
  action: string
  status: string
  template: string
  edited: string
  scheduled: string
  duplicate: string
  duplicateInfo: string
  edit: string
  cancelSend: string
  delete: string
  campaignName: string
  editName: string
  saveName: string
  requiredChangeName: string
  campaignTemplate: string
  editTemplate: string
  close: string
  submit: string
  downloadPatients: string
  downloadPatientsInfo: string
  noOtherCampaigns: string
  statsSent: string
  statsDelivered: string
  statsOpened: string
  statsClicked: string
  statsBounces: string
  statsUnsubscribers: string
  statsInfo: {
    key: string
    description: string
  }[]
}

const campaigns: Campaigns = {
  campaigns: "Campaign",
  action: "Actions",
  status: "Status",
  campaign: "Campaign",
  template: "Template",
  edited: "Created/edited",
  scheduled: "Scheduled release/run datetime",
  duplicate: "Duplicate",
  duplicateInfo: "Change the characteristics of a campaign while preserving the original, by making a duplicate. Then rename it.",
  edit: "Edit",
  cancelSend: "Cancel Send",
  delete: "Delete",
  campaignName: "Campaign Name",
  editName: "Edit Name",
  saveName: "Save Name",
  requiredChangeName: "You are required to change the campaign name.",
  campaignTemplate: "Campaign Template",
  editTemplate: "Edit Template",
  close: "Close",
  submit: "Submit",
  downloadPatients: "Download Patient / Recipient Data",
  downloadPatientsInfo: "Download Patient / Recipient Data Info",
  noOtherCampaigns: "You have no other campaigns",
  statsSent: "Sent",
  statsDelivered: "Delivered",
  statsOpened: "Opened",
  statsClicked: "Clicked",
  statsBounces: "Bounces",
  statsUnsubscribers: "Unsubscribers",
  statsInfo: [
    {
      key: "SENT",
      description:
        "Count of emails our email systems have sent.",
    },
    {
      key: "DELIVERED",
      description:
        "Emails that were accepted by the recipient email server.",
    },
    {
      key: "OPENED",
      description:
        "Recipients that have opened the email.",
    },
    {
      key: "CLICKED",
      description:
        "Recipients that have clicked on at least one link in the email.",
    },
    {
      key: "BOUNCES",
      description:
        "Emails that our email systems were unable to deliver, typically due to an invalid/non-existent email address or spam filter.",
    },
    {
      key: "UNSUBSCRIBERS",
      description:
        "Emails from which a recipient unsubscribed.",
    },
  ],
}

export default campaigns
