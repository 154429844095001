import React from "react"
import tw from "twin.macro"

export enum ButtonColors {
  yellow = "yellow",
  orange = "orange",
  green = "green",
  white = "white",
  black = "black",
  blue = "blue",
  gray = "gray",
  darkGray = "darkGray",
}

const colorStyle: { [key: string]: any } = {
  yellow: tw`bg-yellow-1 hover:bg-yellow-500 border-white text-black`,
  orange: tw`bg-orange-1 hover:bg-orange-600 border-white text-white`,
  green: tw`bg-green-500 hover:bg-green-600 border-white text-white`,
  white: tw`bg-white hover:bg-gray-300 border-gray-400 text-black`,
  black: tw`bg-black hover:bg-gray-800 border-gray-900 text-white`,
  blue: tw`bg-blue-1 hover:bg-blue-600 border-blue-1 text-white`,
  gray: tw`bg-gray-400 hover:bg-gray-500 border-gray-500 text-black`,
  darkGray: tw`bg-gray-901 hover:bg-gray-901 border-gray-901 text-white`,
}

export interface ButtonProps {
  children: any
  color: ButtonColors
  customCss?: any
  onClick?: (props?: any) => any
  disabled?: boolean
  type?: "button" | "reset" | "submit"
}

const CustomButton = ({
  children,
  color,
  customCss = "",
  disabled,
  ...props
}: ButtonProps): React.ReactElement => {
  return (
    <button
      tw="flex items-center px-8 py-2 rounded-full border-solid border transition-all duration-500 text-xs focus:outline-none"
      css={[
        customCss,
        disabled ? tw`cursor-not-allowed` : "",
        colorStyle[color],
      ]}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default CustomButton
