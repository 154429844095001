import { useMatch } from "@reach/router"

import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"

const useCurrentRoute = (): PageOptions | null => {
  let currentStep: PageOptions | null = null
  Object.entries(navigationRoutes).map(([routeKey, value]) => {
    if (useMatch(value)) {
      currentStep = routeKey as PageOptions
    }
  })
  return currentStep
}

export default useCurrentRoute
