import React from "react"
import { Layout as AntLayout } from "antd"
import { css } from "twin.macro"
import { ToastProvider } from "react-toast-notifications"
import { useQuery } from "@apollo/client"

import "./global.css"
import { Localization, possibleOptions } from "@src/localization"
import Header from "./header"
import Footer from "./footer"
import CurrentStep from "@src/context/currentRouteContext"
import useCurrentRoute from "@src/hooks/useCurrentRoute"
import { GET_USER, User } from "@src/queries/user"
import Loading from "@src/components/loading"
import ErrorComponent from "@src/components/ui/error"
import { canAccessEBlast } from "@src/utils/accessControl"

const Layout = ({
  children,
  isPublic = false,
}: {
  children: any
  isPublic?: boolean
}): React.ReactElement => {
  const currentStep = useCurrentRoute()

  // get user
  const getUserResp = useQuery(GET_USER)

  // user
  const user: User = getUserResp && getUserResp.data && getUserResp.data.user

  // show loading until user info is available
  if (getUserResp.loading) {
    return <Loading withOverlay />
  }  

  if (!isPublic && (!user || !canAccessEBlast(user))) {
    return (
      <ErrorComponent msg="Looks like you don't have access to eBlast application. Please contact 4PatientCare Support for access." />
    )
  }  

  return (
    <Localization.Provider value={possibleOptions.en}>
      <CurrentStep.Provider value={currentStep}>
        <ToastProvider>
          <AntLayout hasSider={false}>
            <Header isPublic={isPublic} />
            <AntLayout.Content
              css={[
                css`
                  padding-top: 64px;
                `,
              ]}
            >
              {children}
            </AntLayout.Content>
            <Footer />
          </AntLayout>
        </ToastProvider>
      </CurrentStep.Provider>
    </Localization.Provider>
  )
}

export default Layout
