import header from "./header"
import footer from "./footer"
import homePage from "./homePage"
import homePageGetStartedModal from "./homePageGetStartedModal"
import homePageGladBackModal from "./homePageGladBackModal"
import help from "./help"
import campaigns from "./campaigns"
import createCampaign from "./createCampaign"
import editor from "./editor"
import fileUpload from "./fileUpload"
import fileSelection from "./fileSelection"
import submitCampaign from "./submitCampaign"
import createTemplate from "./createTemplate"
import templateDetails from "./templateDetails"
import textTool from "./textTool"

export default {
  header,
  footer,
  homePage,
  homePageGetStartedModal,
  homePageGladBackModal,
  help,
  campaigns,
  createCampaign,
  editor,
  fileUpload,
  fileSelection,
  submitCampaign,
  createTemplate,
  templateDetails,
  textTool,
}
