export interface HomePage {
  heading: string
  descriptionLine1: string
  descriptionLine2: string
  getStarted: string
  reachOut: string
  benefitLine1: string
  benefitLine2: string
}

const home: HomePage = {
  heading: "Welcome to Self-Service eBlast!",
  descriptionLine1: "Now you can create your eBlasts",
  descriptionLine2:
    ", however you want, whenever you want.",
  getStarted: "Get Started",
  reachOut:
    "Re-engage current and former patients with intriguing, colorful and fully customizable email communications. Whether you're looking to encourage eye health, generate interest in new technology or products, or communicate an emergency office closure, Self-Service eBlast will be your new, go-to tool for getting the word out quickly and easily.",
  benefitLine1: "Your message, your timing, your results.",
  benefitLine2: "",
}

export default home
