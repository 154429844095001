export interface HomePageGetStartedModal {
  heading: string
  headingDescription: string
  footerBtn1: string
  footerBtn2: string
  description: string
  section1Title: string
  section1SubTitle: string
  section1Description: string
  section2Title: string
  section2SubTitle: string
  section2Description: string
}

const home: HomePageGetStartedModal = {
  heading: "Getting Started",
  headingDescription: "Design & Send Email Marketing & Campaigns",
  footerBtn1: "CREATE",
  footerBtn2: "MANAGE",
  description:
    "Send emails for recalls, office notifications, and marketing campaigns via eBlast.",
  section1Title: "Activate eBlast Emails & Campaigns",
  section1SubTitle: "for my practice",
  section1Description:
    "Start using eBlast to send direct mail campaigns to your patients.",
  section2Title: "Manage eBlast Emails & Campaigns",
  section2SubTitle: "for my practice",
  section2Description:
    "Manage eBlast campaigns you’ve created in the past.",
}

export default home
