import React from "react"
import { Layout as AntLayout } from "antd"
import tw from "twin.macro"

import { Localization } from "@src/localization"

const Footer = (): React.ReactElement => {
  const footerContent = React.useContext(Localization).footer

  return (
    <AntLayout.Footer tw="text-center bg-white text-black text-xs border-t border-gray-400 py-4">
      {footerContent.description}
      <span
        css={tw`
          font-bold
        `}
      >
        {" "}
        {footerContent.terms}{" "}
      </span>
      {footerContent.termsPrivacyConnector}
      <span
        css={tw`
          font-bold
        `}
      >
        {" "}
        {footerContent.privacy}
      </span>
    </AntLayout.Footer>
  )
}

export default Footer
