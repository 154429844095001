export interface Help {
  description: string
  emailLabel: string
  email: string
  phoneLabel: string
  phone: string
}

const help: Help = {
  description: "If you need any help or have any questions, ",
  emailLabel: "email us at",
  email: "support@4patientcare.com",
  phoneLabel: "or call",
  phone: "877-479-5484",
}

export default help
