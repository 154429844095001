import { Footer } from "../en/footer"

const footer: Footer = {
  description: "©2020 Postal™ All rights reserved (Fr).",
  terms: "Terms of Use",
  termsPrivacyConnector: "and",
  privacy: "Privacy Policy.",
}

export default footer
