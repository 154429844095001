export interface Editor {
  pageDescription: string
  heading: string
  description: string
}

const editor: Editor = {
  pageDescription: "Click anywhere inside of the template layout on the left to select the placement of the new content type. Then choose the content type you wish to add from the right.",
  heading: "CONTENT TOOL",
  description: "Place your cursor in the location you wish to edit on the layout. Then select one of the Content section buttons below:",
}

export default editor
