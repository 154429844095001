export enum PageOptions {
  HELP = "help",
  CAMPAIGNS = "campaigns",
  PICKTEMPLATE = "pickTemplate",
  PICKSEGMENT = "pickSegment",
  DUPLICATECAMPAIGNPICKSEGMENT = "duplicateCampaignPickSegment",
  EDITCAMPAIGNPICKSEGMENT = "editCampaignPickSegment",
  EDITOR = "editor",
  SUBMITCAMPAIGN = "submitCampaign",
  SUBMITTEDCAMPAIGN = "submittedCampaign",
  TEMPLATEMANAGEMENT = "templateManagement",
  TEMPLATECREATEDETAILS = "templateCreateDetails",
  TEMPLATEDUPLICATEDETAILS = "templateDuplicateDetails",
  TEMPLATEEDITDETAILS = "templateEditDetails",
  TEMPLATEEDITOR = "templateEditor",
  SENDTESTEMAIL = "sendTestEmail",
  SENDTESTEMAILSUCCESS = "sendTestEmailSuccess",
}

export const navigationRoutes: { [key: string]: string } = {
  [PageOptions.HELP]: "/help",
  [PageOptions.CAMPAIGNS]: "/campaigns",
  [PageOptions.PICKTEMPLATE]: "/create",
  [PageOptions.PICKSEGMENT]: "/create/:templateId/segment",
  [PageOptions.DUPLICATECAMPAIGNPICKSEGMENT]: "/create/:campaignId/duplicate/segment",
  [PageOptions.EDITCAMPAIGNPICKSEGMENT]: "/create/:campaignId/edit/segment",
  [PageOptions.EDITOR]: "/edit/:campaignId/editor",
  [PageOptions.SUBMITCAMPAIGN]: "/campaigns/:campaignId/submit",
  [PageOptions.SUBMITTEDCAMPAIGN]: "/campaigns/:campaignId/submitted",
  [PageOptions.TEMPLATEMANAGEMENT]: "/templates",
  [PageOptions.TEMPLATECREATEDETAILS]: "/templates/create/details",
  [PageOptions.TEMPLATEDUPLICATEDETAILS]: "/templates/:templateId/duplicate/details",
  [PageOptions.TEMPLATEEDITDETAILS]: "/templates/:templateId/edit/details",
  [PageOptions.TEMPLATEEDITOR]: "/templates/:templateId/editor",
}

export const nestedNavigationRoutes: { [key: string]: string } = {
  [PageOptions.SENDTESTEMAIL]:
    "send-test",
  [PageOptions.SENDTESTEMAILSUCCESS]:
    "send-test/success",
}

export type CreateCampaign = {
  [navStep in PageOptions]: {
    heading: string
    navTitle?: string
    createContentTitle?: string
    info?: string
    infoBtnText?: string
    infoBtnLink?: string
  }
} & {
  next: string
  templates: string
}

const createCampaign: CreateCampaign = {
  next: "NEXT - PREVIEW TEMPLATE ",
  templates: "TEMPLATES",
  help: {
    heading: "Contact Us",
  },
  campaigns: {
    heading: "Campaigns",
    createContentTitle: "Manage",
    info:
      "Manage, duplicate or delete an existing direct mail campaign, or create a new campaign. Access individual templates within a campaign quickly.",
    infoBtnText: "Create New Campaign",
    infoBtnLink: navigationRoutes[PageOptions.PICKTEMPLATE],
  },
  pickTemplate: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign",
    createContentTitle: "Pick a Template",
    info:
      "Start creating your email by choosing the right email template to suit your needs. Use one of our pre-designed templates or create your own.",
  },
  pickSegment: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign",
    createContentTitle: "Template Review",
    info:
      "Choose your <b>marketing segments</b> and <b>add notes</b> for other coworkers to help them understand what you intend to customize in the template. ",
  },
  duplicateCampaignPickSegment: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign",
    createContentTitle: "Template Review",
    info:
      "Choose your <b>marketing segments</b> and <b>add notes</b> for other coworkers to help them understand what you intend to customize in the template. ",
  },
  editCampaignPickSegment: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign",
    createContentTitle: "Template Review",
    info:
      "Choose your <b>marketing segments</b> and <b>add notes</b> for other coworkers to help them understand what you intend to customize in the template. ",
  },
  editor: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign",
    navTitle: "Editor",
    createContentTitle: "Create Email",
    info:
      'To add new content, select the place on the layout you want to change. Then use the Content Tool to add or edit content. <b>Choose: Column, Button, Image, Social, or Text</b>. Or, select and edit existing content within the current layout. Use the <b>"TOOLS"</b> bar at the top to <b>Spell Check, Revert<b> (multiple steps), <b>Preview</b> in Desktop/Mobile and more.',
  },
  submitCampaign: {
    heading: "Campaigns",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing and mailing.",
  },
  submittedCampaign: {
    heading: "Campaigns",
    info:
      "Select a color palette, upload your corporate logo, pick images, add headlines and copy, and adjust your settings for printing and mailing.",
  },
  sendTestEmail: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign",
    navTitle: "Editor",
    createContentTitle: "Create Email",
    info:
      'To add new content, select the place on the layout you want to change. Then use the Content Tool to add or edit content. <b>Choose: Column, Button, Image, Social, or Text</b>. Or, select and edit existing content within the current layout. Use the <b>"TOOLS"</b> bar at the top to <b>Spell Check, Revert<b> (multiple steps), <b>Preview</b> in Desktop/Mobile and more.',
  },
  sendTestEmailSuccess: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign",
    navTitle: "Editor",
    createContentTitle: "Create Email",
    info:
      'To add new content, select the place on the layout you want to change. Then use the Content Tool to add or edit content. <b>Choose: Column, Button, Image, Social, or Text</b>. Or, select and edit existing content within the current layout. Use the <b>"TOOLS"</b> bar at the top to <b>Spell Check, Revert<b> (multiple steps), <b>Preview</b> in Desktop/Mobile and more.',
  },
  templateManagement: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign Template Management",
    createContentTitle: "Template Management",
    info:
      "Start by choosing a template to edit below or create one from scratch by choosing <b>Create New Template</b> to the right. Editing is limited to your current access. ",
    infoBtnText: "Create New Template",
    infoBtnLink: navigationRoutes[PageOptions.TEMPLATECREATEDETAILS],
  },
  templateCreateDetails: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign Template Management",
    createContentTitle: "Template Management",
    info:
      "Start by choosing a template to edit below or create one from scratch by choosing <b>Create New Template</b> to the right. Editing is limited to your current access. ",
  },
  templateDuplicateDetails: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign Template Management",
    createContentTitle: "Template Management",
    info:
      "Start by choosing a template to edit below or create one from scratch by choosing <b>Create New Template</b> to the right. Editing is limited to your current access. ",
  },
  templateEditDetails: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign Template Management",
    createContentTitle: "Template Management",
    info:
      "Start by choosing a template to edit below or create one from scratch by choosing <b>Create New Template</b> to the right. Editing is limited to your current access. ",
  },
  templateEditor: {
    heading: "Create&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Campaign Template Management",
    createContentTitle: "Template Management",
    info:
      'To add new content, select the place on the layout you want to change. Then use the Content Tool to add or edit content. <b>Choose: Column, Button, Image, Social, or Text</b>. Or, select and edit existing content within the current layout. Use the <b>"TOOLS"</b> bar at the top to <b>Spell Check, Revert<b> (multiple steps), <b>Preview</b> in Desktop/Mobile and more.',
  },
}

export default createCampaign
