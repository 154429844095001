export interface TemplateDetails {
  heading: string
  description: string
  templateName: string
  templateNameInfo: string
  templateNamePlaceholder: string
  templateType: string
  templateTypeInfo: string
  templateTypePlaceholder: string
  selectCoversLabel: string
  selectCoversInfo: string
  selectCoversPlaceholder: string
  back: string
  createTemplateBtn: string
  createDuplicateTemplateBtn: string
  editTemplateBtn: string
}

const templateDetails: TemplateDetails = {
  heading: "Fully Edit & Customize This Email Template",
  description: "To unlock the full editing capabilities of this template, please review the template and create a template name and choose your custom localization to have greater control over editing and custom creation features.",
  templateName: "Template Name",
  templateNameInfo: "Template Name Info",
  templateNamePlaceholder: "Prebook Template",
  templateType: "Template Type",
  templateTypeInfo: "Template Type Info",
  templateTypePlaceholder: "Select...",
  selectCoversLabel: "Select Cover(s)",
  selectCoversInfo: "Covers that should have access to this template",
  selectCoversPlaceholder: "Please select...",
  back: "BACK",
  createTemplateBtn: "CREATE TEMPLATE",
  createDuplicateTemplateBtn: "CREATE DUPLICATE TEMPLATE",
  editTemplateBtn: "EDIT TEMPLATE",
}

export default templateDetails
