export interface Header {
  create: string
  campaigns: string
  templateManagement: string
  help: string
  backToDashboard: string
}

const header: Header = {
  create: "Create",
  campaigns: "Campaigns",
  templateManagement: "Template Management",
  help: "Help",
  backToDashboard: "Back to Dashboard",
}

export default header
