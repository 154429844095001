interface FileUpload {
  heading: string
  browse: string
  maxFileSizeLabel: string
  maxFileSizeValue: string
  acceptableFormats: string
  tagDescription: string
  location: string
  provider: string
}

const fileUpload: FileUpload = {
  heading: "Custom Corporate Assets",
  browse: "Browse",
  maxFileSizeLabel: "Maximum File Size",
  maxFileSizeValue: "1MB",
  acceptableFormats: "Acceptable Formats",
  tagDescription: "Before uploading your new image, please tag the image to a location and a provider.",
  location: "By Location",
  provider: "By Provider",
}

export default fileUpload
