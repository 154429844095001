import React from "react"
import tw, { css } from "twin.macro"

import Spinner from "@src/assets/spinner.svg"

interface LoadingProps {
  customCss?: any
  withOverlay?: boolean
}

const Loading = ({
  customCss = "",
  withOverlay = false,
}: LoadingProps): React.ReactElement => {
  return (
    <div
      css={[
        tw`flex items-center justify-center`,
        withOverlay
          ? css`
              position: fixed;
              top: 0px;
              left: 0px;
              height: 100%;
              width: 100%;
              background-color: rgba(0, 0, 0, 0.6);
              z-index: 1001;
            `
          : tw``,
      ]}
    >
      <Spinner
        alt="Loading"
        tw="text-blue-300"
        css={[
          customCss,
          css`
            width: 3.5rem;
            height: 3.5rem;
          `,
        ]}
      />
    </div>
  )
}

export default Loading
