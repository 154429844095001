import gql from "graphql-tag"

export interface Cover {
  coverKey: string
  orgName: string
  status: number
}

export const COVER_FRAGMENT = gql`
  fragment Cover on User {
    coverKey
    orgName
    status
  }
`

export const GET_COVERS = gql`
  query getCovers {
    covers @rest(type: "[Cover]", path: "/v1/covers") {
      coverKey
      orgName
      status
    }
  }
`
