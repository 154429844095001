import gql from "graphql-tag"

import { Cover, COVER_FRAGMENT } from "./cover"

interface Session {
  sessionId: string
  ipAddress: string
  userId: string
  canAccessSite: boolean
  canManageTemplates: boolean
}

const SESSION_FRAGMENT = gql`
  fragment Session on Session {
    sessionId
    ipAddress
    userId
    canAccessSite
    canManageTemplates
  }
`

export interface Location {
  locKey: string
  orgName: string
  status: number
}

const LOCATION_FRAGMENT = gql`
  fragment Location on Location {
    locKey
    orgName
    status
  }
`

interface Schedule {
  id: number
  providerAcctKey: string
  locKey: string
}

const SCHEDULE_FRAGMENT = gql`
  fragment Schedule on Schedule {
    id
    providerAcctKey
    locKey
  }
`

export interface Provider {
  acctKey: string
  title: string
  firstName: string
  lastName: string
  degree: string
  status: number
  schedules: Schedule[]
}

const PROVIDER_FRAGMENT = gql`
  fragment Provider on Provider {
    acctKey
    title
    firstName
    lastName
    degree
    status
  }
`

interface OnlyUser {
  userId: string
  hasViewedGetStarted: boolean
  isSupportUser: boolean
}

const ONLY_USER_FRAGMENT = gql`
  fragment OnlyUser on OnlyUser {
    userId
    hasViewedGetStarted
    isSupportUser
  }
`

export interface User extends Cover {
  session: Session
  locations: Location[]
  providers: Provider[]
  user: OnlyUser
  hasShadowPatients: boolean
}

const USER_FRAGMENT = gql`
  fragment User on User {
    ...Cover
    session @type(name: "Session") {
      ...Session
    }
    locations @type(name: "Location") {
      ...Location
    }
    providers @type(name: "Provider") {
      ...Provider
      schedules @type(name: "Schedule") {
        ...Schedule
      }
    }
    user @type(name: "OnlyUser") {
      ...OnlyUser
    }
    hasShadowPatients
  }
  ${COVER_FRAGMENT}
  ${SESSION_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${PROVIDER_FRAGMENT}
  ${SCHEDULE_FRAGMENT}
  ${ONLY_USER_FRAGMENT}
`

export const GET_USER = gql`
  query getUser {
    user @rest(type: "User", path: "/v1/user") {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

export const GET_STARTED = gql`
  mutation getStarted($body: any!) {
    getStarted(body: $body)
      @rest(
        path: "/v1/user/get-started"
        method: "POST"
        bodyKey: "body"
        type: "OnlyUser"
      ) {
      ...OnlyUser
    }
  }
  ${ONLY_USER_FRAGMENT}
`
