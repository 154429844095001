import React from "react"
import { Alert } from "antd"
import tw from "twin.macro"

const ErrorComponent = ({ msg }: { msg: string }): React.ReactElement => (
  <div css={[tw`bg-white p-8`]}>
    <Alert banner message="Error" description={msg} type="error" showIcon />
  </div>
)

export default ErrorComponent
