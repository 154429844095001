import React from "react"
import { Layout as AntLayout, Menu, Avatar, Dropdown } from "antd"
import tw, { css } from "twin.macro"
import { UserOutlined, DownOutlined, LeftOutlined } from "@ant-design/icons"
import { withPrefix, Link } from "gatsby"
import { useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import Logo from "@src/assets/logo.svg"
import { Localization } from "@src/localization"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import useCurrentRoute from "@src/hooks/useCurrentRoute"
import { GET_USER, User } from "@src/queries/user"
import Loading from "../loading"
import { logout, getToken } from "@src/utils/auth"
import { canManageTemplates } from "@src/utils/accessControl"

const UserDropdown = () => (
  <Menu>
    <Menu.Item>
      <div
        onClick={() => {
          logout()
          location.href = location.origin + "/logout"
        }}
      >
        Logout
      </div>
    </Menu.Item>
  </Menu>
)

const Header = ({ isPublic }: { isPublic: boolean }): React.ReactElement => {
  const headerContent = React.useContext(Localization).header
  const currentRoute = useCurrentRoute()

  // get user
  const getUserResp = useQuery(GET_USER, {
    skip: isPublic,
  })

  // user
  const user: User = getUserResp && getUserResp.data && getUserResp.data.user

  // show loading until user info is available
  if (!isPublic && (getUserResp.loading || !user)) {
    return <Loading withOverlay />
  }

  const menuItems = [
    {
      to: navigationRoutes[PageOptions.PICKTEMPLATE],
      content: headerContent.create,
      toActiveIn: [
        PageOptions.PICKTEMPLATE,
        PageOptions.PICKSEGMENT,
        PageOptions.DUPLICATECAMPAIGNPICKSEGMENT,
        PageOptions.EDITOR,
      ],
      hasAccess: true,
    },
    {
      to: navigationRoutes[PageOptions.TEMPLATEMANAGEMENT],
      content: headerContent.templateManagement,
      toActiveIn: [
        PageOptions.TEMPLATEMANAGEMENT,
        PageOptions.TEMPLATEDUPLICATEDETAILS,
        PageOptions.TEMPLATEEDITDETAILS,
        PageOptions.TEMPLATECREATEDETAILS,
        PageOptions.TEMPLATEEDITOR,
      ],
      hasAccess: user && canManageTemplates(user),
    },
    {
      to: navigationRoutes[PageOptions.CAMPAIGNS],
      content: headerContent.campaigns,
      toActiveIn: [PageOptions.CAMPAIGNS],
      hasAccess: true,
    },
    {
      to: navigationRoutes[PageOptions.HELP],
      content: headerContent.help,
      toActiveIn: [PageOptions.HELP],
      hasAccess: true,
    },
  ]

  // selected routes
  const selectedKeys: string[] = []
  menuItems.forEach(oneItem => {
    if (currentRoute && oneItem.toActiveIn.includes(currentRoute)) {
      selectedKeys.push(oneItem.to)
    }
  })

  return (
    <AntLayout.Header
      css={[
        tw`text-gray-700 bg-white flex items-center fixed z-50 w-full border-b border-gray-400`,
      ]}
    >
      {/* import fonts */}
      <Helmet>
        <title>eBlast</title>
        <link
          href={withPrefix("public.css")}
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>

      {/* logo */}
      <Link to={user ? "/" : "#"}>
        <Logo tw="w-40" />
      </Link>

      {/* nav links */}
      {user && (
        <>
          <Menu
            selectedKeys={selectedKeys}
            mode="horizontal"
            css={[
              tw`pl-2 lg:pl-8 xl:pl-16`,
              css`
                height: calc(100% - 2px);
              `,
            ]}
          >
            {menuItems.map(
              menuItem =>
                menuItem.hasAccess && (
                  <Menu.Item
                    key={menuItem.to}
                    tw="mx-6 p-0"
                    style={{ lineHeight: "35px", verticalAlign: "super" }}
                  >
                    <Link to={menuItem.to}>{menuItem.content}</Link>
                  </Menu.Item>
                )
            )}
          </Menu>

          <div tw="flex flex-grow justify-center">
            <a
              href={`https://www.4patientcare.ws/dashboardv3/web/CombRemPreService.aspx?SessionID=${getToken()}`}
              tw="flex items-center text-sm text-gray-700 leading-4 cursor-pointer"
            >
              <LeftOutlined tw="mr-2" />
              {headerContent.backToDashboard}
            </a>
          </div>

          <div tw="flex items-center cursor-pointer">
            <Avatar icon={<UserOutlined tw="w-full" />} tw="mr-2" />
            <Dropdown
              overlay={UserDropdown}
              trigger={["click"]}
              placement="bottomRight"
            >
              <div tw="flex items-center leading-tight">
                <div tw="mr-2">
                  {user.session.userId}
                  <br />
                  {user.orgName}
                </div>
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </>
      )}
    </AntLayout.Header>
  )
}

export default Header
