interface SubmitCampaign {
  heading: string
  description: string
  campaign: string
  template: string
  marketSegment: string
  segmentRecipients: string
  uploadPatientRecipientList: string
  uploadCSVFile: string
  uploadingCSVFile: string
  recipients: string
  activeRecipients: string
  runDate: string
  runTime: string
  emailSubject: string
  footerHeading: string
  submittedHeading: string
  submittedDescription: string
  submittedFooterHeading: string
  submittedFooterTooltip: string
  uploadFileTypeError: string
  uploadNoContentError: string
  uploadSuccess: string
  validateDataBtn: string
  uploadCsvTooltip: string
  dragAndPlaceIDBadges: string
}

const submitCampaign: SubmitCampaign = {
  heading: "Congratulations, you’re almost there!",
  description:
    "You've successfully created your new eBlast template! <strong>To submit your campaign, please complete the information below and select Confirm & Submit.</strong>",
  campaign: "Campaign",
  template: "Template",
  marketSegment: "Patient Segment",
  segmentRecipients: "Segment Recipients",
  uploadPatientRecipientList: "Upload Patient Recipient List",
  uploadCSVFile: "Upload CSV File",
  uploadingCSVFile: "Uploading CSV File",
  recipients: "Recipients",
  activeRecipients: "Active Recipients",
  runDate: "Release / Run Date",
  runTime: "Time",
  emailSubject: "Email Subject Line",
  footerHeading: "Submit your email to release on your scheduled release date",
  submittedHeading: "YOU’RE CONFIRMED!",
  submittedDescription:
    "A copy of your campaign has been stored under the <b>Campaigns link</b> above.",
  submittedFooterHeading:
    "Didn’t mean to send this campaign?<br/>Click the <b>icon</b> for instructions.",
  submittedFooterTooltip:
    "If you accidentally sent your campaign at the wrong date or time, or simply changed your mind before the release date, you can cancel the send by accessing the campaign in the “Campaigns” section and selecting the “CANCEL” link, next to the run date/time.",
  uploadFileTypeError: "UPLOAD ERROR! Wrong File Type",
  uploadNoContentError: "UPLOAD ERROR! Missing Content",
  uploadSuccess: "SUCCESS!",
  validateDataBtn: " VALIDATE DATA",
  uploadCsvTooltip:
    "If your data fields do not match up with the required values represented in the segmentation ID badges, then your data is incomplete. Please reupload a complete data file.",
  dragAndPlaceIDBadges:
    "Drag and place the ID badges into their appropriate segments",
}

export default submitCampaign
