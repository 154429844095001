import React from "react"

import en from "./en"
import fr from "./fr"

export const possibleOptions = {
  en,
  fr: {
    ...en,
    ...fr,
  },
}

export const Localization = React.createContext(possibleOptions.en)
